import React, { useState } from "react"
import { navigate } from "gatsby"
import axios from "axios"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Body } from "./typography"
import { Textarea } from "theme-ui"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const ContactForm = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [company, setCompany] = useState("")
  const [city, setCity] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  

  const handleSubmit = async event => {
    
    console.log("----")
    event.preventDefault()

    const result = await executeRecaptcha("dynamicAction")

    setLoading(true)
    const data = {
      name: name,
      email: email,
      company: company,
      city: city,
      phone: phone,
      message: message,
    }

    if (result)
      axios
        .post("/.netlify/functions/sendMail", data)
        .then(function (response) {
          setSuccess(true)
          setLoading(false)

          if (typeof window !== "undefined" && window.dataLayer !== undefined) {
            window.dataLayer = window.dataLayer || []

            window.dataLayer.push({
              event: "formSubmission",
              formType: "Contact",
            })
          }
        })
        .catch(function (error) {
          setSuccess(false)
          setLoading(false)
        })
    else {
      setSuccess(false)
      setLoading(false)
    }
  }
  

  return (
    <Wrapper id="form">
      <form method="post" name="download" onSubmit={handleSubmit}>
        <section>
          <label htmlFor="name">Nome e cognome *</label>
          <input
            onChange={e => setName(e.target.value)}
            type="text"
            name="name"
            id="name"
            value={name}
            required
          />
        </section>
        <section>
          <label htmlFor="city">Città *</label>
          <input
            onChange={e => setCity(e.target.value)}
            type="text"
            name="city"
            id="city"
            value={city}
            required
          />
        </section>
        <section>
          <label htmlFor="phone">Telefono *</label>
          <input
            onChange={e => setPhone(e.target.value)}
            type="phone"
            name="phone"
            id="phone"
            value={phone}
            required
          />
        </section>
        <section>
          <label htmlFor="email">Email *</label>
          <input
            onChange={e => setEmail(e.target.value)}
            type="email"
            name="email"
            id="email"
            value={email}
            required
          />
        </section>
        <section>
          <label htmlFor="company">Azienda</label>
          <input
            onChange={e => setCompany(e.target.value)}
            type="text"
            name="company"
            id="company"
            value={company}
          />
        </section>
        <section>
          <label htmlFor="message">Messaggio *</label>
          <Textarea
            onChange={e => setMessage(e.target.value)}
            type="textarea"
            name="message"
            id="message"
            value={message}
            rows="3"
            required
          />
        </section>
        <section>
          <p>
            Dopo aver preso visione della presente informativa sulla privacy ai
            sensi dell'ART.13 D.Lgs. n.196 del 2003, a) Acconsento al
            trattamento dei dati personali al fine esclusivo di avere
            informazioni riguardo i servizi relativi al presente sito web. Per
            completare la registrazione è necessaria l'autorizzazione al
            trattamento dei dati personali.
          </p>
        </section>
        <Checkbox>
          <input type="checkbox" id="privacy" name="privacy" required />
          <label htmlFor="privacy">Si, acconsento</label>
        </Checkbox>
        <section>
          {!success && (
            <input type="submit" value={loading ? `Caricamento...` : `Invia`} />
          )}
          {success && (
            <input type="submit" value="Richiesta inviata!" disabled />
          )}
        </section>
      </form>
    </Wrapper>
  )
}

export default ContactForm

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  div {
    margin: 0 ${DesignTokens.spacing[2]};
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

const Checkbox = styled.div`
  display: flex;
  flex-direction: row;
  input {
    margin-right: 0.5rem;
  }
  label {
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 0 !important;
  }
`

const RadioButton = styled.div`
  display: flex;
  flex-direction: row;
  input {
    margin-right: 0.5rem;
  }
  label {
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 0 !important;
  }
`

const Wrapper = styled.div`
  background: ${DesignTokens.colors.primary[500]};
  /* padding: 2rem; */
  border-radius: ${DesignTokens.borderRadius};
  margin-bottom: 6rem;
  form {
    padding-top: 2rem;
    label {
      margin-bottom: 0.25rem;
      color: white;
      font-size: 14px;
    }
    input,
    textarea {
      padding: ${DesignTokens.spacing[3]};
      color: white;
      border: none;
      font-size: 16px;
      background: transparent;
      border-bottom: 1px solid ${DesignTokens.colors.primary[400]};
      &:hover,
      &:focus {
        border-bottom: 1px solid ${DesignTokens.colors.secondary[500]};
      }
    }

    input[type="submit"] {
      background: ${DesignTokens.colors.white};
      border: none;
      color: ${DesignTokens.colors.primary[500]};
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      &:hover,
      &:active {
        background: ${DesignTokens.colors.primary[400]};
      }
      &:disabled {
        background: white;
        cursor: default;
      }
    }
    section {
      display: flex;
      flex-direction: column;
      p {
        font-size: 12px;
        color: ${DesignTokens.colors.primary[200]};
      }
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[4]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
