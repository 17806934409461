import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import TextHero from "../components/textHero"
import Description from "../components/description"
import Gallery from "../components/gallery"
import styled from "styled-components"
import Footer from "../components/footer"
import Focus from "../components/focus"
import Form from "../components/form"
import DesignTokens from "../components/designTokens"

const ContactPage = ({ data: { page } }) => {
  // console.log(page)
  return (
    <Layout>
      <SEO title={page.seo.title} />
      <Wrapper>
      <Header theme="dark" />
        <Form title={page.title} contactLinks={page.contactLinks} />
      </Wrapper>
    </Layout>
  )
}

export default ContactPage

const Wrapper = styled.div`
background-color: ${DesignTokens.colors.primary[500]};
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const query = graphql`
  query ContactPageQuery {
    page: datoCmsContactPage {
      title
      contactLinks {
        url
        anchorText
        id
      }
      seo {
        title
      }
    }
  }
`
