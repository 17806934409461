import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col } from "react-grid-system"
import { Caption, DisplaySmall } from "./typography"
import RichContentTextBlock from "./richContentTextBlock"

const TextHero = ({ label, title, subtitle }) => (
  <Wrapper>
    <Container>
      <Row justify="center">
        <Col xl={8}>
          <Content>
            <RichContentTextBlock html={title} />
          </Content>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

const Wrapper = styled.section`
  background-color: ${DesignTokens.colors.primary[500]};
`

const Content = styled.section`
  padding-top: 3rem;
  padding-bottom: 3rem;
  min-height: calc(80vh - 200px);
  display: flex;
  align-items: center;
  color: white;
  text-align: center;
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default TextHero
