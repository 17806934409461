import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col } from "react-grid-system"
import ContactForm from "./contactForm"
import { Body, DisplaySmall } from "./typography"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const Form = ({ title, contactLinks }) => (
  <Wrapper>
    <Container>
      <Row>
        <Col xl={6}>
          <DisplaySmall as="h1">{title}</DisplaySmall>
          <Content>
            {contactLinks.map(link => (
              <CustomOutboundLink
                href={link.url}
                target="blank"
                rel="noopener nofollow"
                key={link.id}
              >
                {link.anchorText}
              </CustomOutboundLink>
            ))}
          </Content>
        </Col>
        <Col xl={6}>
          <ContactForm />
        </Col>
      </Row>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
  background-color: ${DesignTokens.colors.primary[500]};
  color: white;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  font-size: 1rem;
  & > * {
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CustomOutboundLink = styled(OutboundLink)`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export default Form
